<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <template>
      <suppliers-list-add-new
        :is-add-new-suppliers-sidebar-active.sync="isAddNewSupplierSidebarActive"
        @refetch-data="refetchData"
      />

      <b-row>
        <b-col
          cols="12"
        >
          <template>
            <!-- Table Container Card -->
            <b-card
              no-body
            >
              <div class="m-2">
                <!-- Table Top -->
                <b-row>
                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <label>Entries</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <div class="d-flex align-items-center justify-content-end">
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Zoeken op naam..."
                      />

                      <b-button
                        variant="primary"
                        @click="isAddNewSupplierSidebarActive = true"
                      >
                        <span class="text-nowrap">Toevoegen</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-table
                ref="refSupplierListTable"
                :items="fetchSuppliers"
                responsive
                :fields="tableColumns"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                show-empty
                empty-text="Geen overeenkomende records gevonden"
                class="position-relative suppliers-table"
              >
                <template #cell(id)="data">
                  <b-link
                    :to="{ name: 'app-labels-edit', params: { id: data.item.id }}"
                    class="font-weight-bold"
                  >
                    #{{ data.value }}
                  </b-link>
                </template>
                <template #cell(name)="data">
                  <b-link
                    :to="{ name: 'app-labels-edit', params: { id: data.item.id }}"
                    class="font-weight-bold"
                  >
                    <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          ref="previewEl"
                          :src="data.item.logoFileURL"
                          size="32"
                          square
                          variant=""
                        />
                      </template>
                      {{ data.item.name }}

                    </b-media>
                  </b-link>
                </template>
                <template #cell(createTimestamp)="data">
                  <div class="text-nowrap">
                    <span class="align-text-top text-capitalize">{{ formatDateTime(data.value) }}</span>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <b-link
                      :id="`supplier-view-action-row-${data.item.id}`"
                      :to="{ name: 'app-labels-edit', params: { id: data.item.id }}"
                    >
                      <feather-icon
                        :id="`supplier-row-${data.item.id}-preview-icon`"
                        class="cursor-pointer mx-1 feather-hover"
                        icon="EyeIcon"
                        size="16"
                      />
                    </b-link>
                    <b-tooltip
                      :target="`supplier-view-action-row-${data.item.id}`"
                      placement="left"
                    >
                      <p class="mb-0">
                        Aanpassen
                      </p>
                    </b-tooltip>
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>

                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalSuppliers"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>

                </b-row>
              </div>
            </b-card>
          </template>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BTable,
  BLink,
  BOverlay,
  BPagination,
  BFormInput,
  BTooltip,
  BMedia,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { formatDateTime } from '@core/utils/utils'
import { onUnmounted, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import supplierStoreModule from '@/views/pages/supplier/supplierStoreModule'
import useSuppliersList from '@/views/pages/supplier/useSuppliersList'
import SuppliersListAddNew from '@/views/pages/supplier/supplier-add/SuppliersListAddNew.vue'

export default {
  components: {
    SuppliersListAddNew,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BTable,
    BLink,
    BOverlay,
    BPagination,
    vSelect,
    BTooltip,
    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SUPPLIERS_APP_STORE_MODULE_NAME = 'app-labels-list'

    // Register module
    if (!store.hasModule(SUPPLIERS_APP_STORE_MODULE_NAME)) store.registerModule(SUPPLIERS_APP_STORE_MODULE_NAME, supplierStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPLIERS_APP_STORE_MODULE_NAME)) store.unregisterModule(SUPPLIERS_APP_STORE_MODULE_NAME)
    })

    const isAddNewSupplierSidebarActive = ref(false)

    const {
      fetchSuppliers,
      tableColumns,
      perPage,
      currentPage,
      totalSuppliers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      refSupplierListTable,
      refetchData,
      isSortDirDesc,
      showOverlay,
    } = useSuppliersList()

    return {
      fetchSuppliers,
      totalSuppliers,
      refSupplierListTable,
      isAddNewSupplierSidebarActive,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      sortBy,
      refetchData,
      showOverlay,
      searchQuery,
      avatarText,
      isSortDirDesc,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }

  .categorie-table{
    .description{
      width:30%;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
